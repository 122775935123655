@font-face {
  font-family: 'PT-Sans';
  src: url('./assets//fonts/PT_Sans/PTSans-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('woff');
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('woff');
  font-weight: 500;
}


@font-face {
  font-family: 'SourceSansPro';
  src: url('./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf') format('woff');
}
@font-face {
  font-family: 'SourceSansItalic';
  src: url('./assets/fonts/Source_Sans_Pro/SourceSansPro-Italic.ttf') format('woff');
}
@font-face {
  font-family: 'SourceSansProBold';
  src: url('./assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf') format('woff');
}
@font-face {
  font-family: 'SourceSansProSemiBold';
  src: url('./assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf') format('woff');
}
@font-face {
  font-family: 'SourceSansProLight';
  src: url('./assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf') format('woff');
}

@font-face {
  font-family: 'Sora';
  src: url('./assets/fonts/Sora/Sora-Regular.otf') format('woff');
}
@font-face {
  font-family: 'SoraBold';
  src: url('./assets/fonts/Sora/Sora-Bold.otf') format('woff');
}